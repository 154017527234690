import React, { useMemo } from 'react'

export const ConfigContext = React.createContext({
  rootProps: null,
  isHpx: false
})

const ConfigProvider = (props) => {
  const { rootProps, store } = props

  /*
  declare functions to get and set store data here
  the functions from props will be used if they are passed in
  otherwise, the functions from utils/Utils.js will be used
   */
  const isHpx = ['hpxandroid', 'hpxios', 'hpxmac', 'hpxwindows'].includes(
    store?.state?.onboarding?.sessionContext?.app?.clientName
  )

  const configState = useMemo(() => {
    return {
      rootProps,
      isHpx
    }
  }, [rootProps, isHpx])

  return (
    <ConfigContext.Provider value={configState}>
      {props.children}
    </ConfigContext.Provider>
  )
}

export default ConfigProvider
