import React, { useContext } from 'react'
import { ConfigContext } from '../store/ConfigContext'
import ProgressIndicator from '@veneer/core/dist/esm/scripts/progress_indicator'
import {
  SetupAndOnboardingViewContainer,
  UiTheme,
  MultiUiBrandProvider
} from '@jarvis/react-setup-and-onboarding-addons'

const Layout = ({ children }) => {
  const { rootProps, isHpx } = useContext(ConfigContext)

  return (
    <MultiUiBrandProvider uiBrand={isHpx ? UiTheme.hpx : UiTheme.hpsmart}>
      <SetupAndOnboardingViewContainer>
        {children ? (
          children(rootProps)
        ) : (
          <ProgressIndicator
            data-testid="progress"
            appearance="circular"
            behavior="indeterminate"
          />
        )}
      </SetupAndOnboardingViewContainer>
    </MultiUiBrandProvider>
  )
}

export default Layout
