import React from 'react'
import Layout from './Layout'
import ConfigProvider from '../store/ConfigContext'

const SetupAndOnboardingLayout = ({ rootProps, store, children }) => {
  return (
    <ConfigProvider store={store} rootProps={rootProps}>
      <Layout>{children}</Layout>
    </ConfigProvider>
  )
}

export default SetupAndOnboardingLayout
