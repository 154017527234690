import React from 'react'
import { ShellProps } from '../src/types/shell'
import SetupAndOnboardingLayout from '../src/components/index'
import projectNames from '../src/configs/projectNames'

declare global {
  interface Window {
    Shell: ShellProps
  }
}

/**
 * @function Root Main function
 * @param stack
 * @param children
 * @param props
 * @returns
 */
export default function Root({ children, ...props }) {
  const {
    v1: { store }
  } = window.Shell as ShellProps

  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <SetupAndOnboardingLayout rootProps={props} store={store}>
        {children}
      </SetupAndOnboardingLayout>
    </section>
  )
}
